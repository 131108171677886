body{
    overflow: hidden;
    // display: flex;
    // flex-wrap: wrap;
}
@font-face {
    font-family: 'DOS Font';
    src: url('../fonts/ModernDOS9x14.ttf') format('truetype');
}
.mint1-screen {
    background: url('../images/background_screen.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: #53fb54;
    font-family: 'DOS Font', Courier, monospace;
    width: 100%;
    height: 100vh;
    padding: 80px;

    
    .text-row {
        line-height: 1.5em;
        display: inline;
    }
    
    .blink {
        animation: blink 1s step-start infinite;
    }
}

@keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: #53fb54;
    }
}
@media (max-width: 768px) {
    .mint1-screen {
        padding: 20px;
    }
}