@font-face {
    font-family: 'DOS Font';
    src: url('../fonts/ModernDOS9x14.ttf') format('truetype');
}

body {
    overflow: hidden;
    font-family: 'DOS Font', Courier, monospace;
}

.mayor {
    background: url('../images/background_screen.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: #41f341;
    height: 100vh;

    .logo-image {
        width: 400px;
    }
    
    .mint-time {
        margin-top:-100px;
        margin-bottom: 15px;
        width: 100%;
        height: 70px;
        border: 2px solid #1c5d1b;
        outline: 2px solid #3de63d;
        outline-offset: 2px;
    }
    
    .mint-time-div {
        width: 350px;
    }
    .text-row {
        display: inline;
    }
    
    .blink {
        animation: blink 1s step-start infinite;
    }
}



@keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: #53fb54;
    }
}
@media (max-width: 768px) {
    .mint-time-div {
        width: 250px !important;
    }
}